'use client';

import styled, { css } from 'styled-components';
import palette from '@miniintern/styles/palette';

/** 버튼 형태 */
export type ButtonType = 'primary' | 'line' | 'text';
/** 버튼 크기 */
export type ButtonSize = 'xLarge' | 'large' | 'medium' | 'small' | 'xSmall';
/** 버튼 색상 */
export type ButtonColor =
  | 'blue_500'
  | 'rc_blue_500'
  | 'black'
  | 'red_500'
  | 'orange_500'
  | 'green_500';

/** 버튼 사이즈에 따른 스타일 ( `gap`, `height`, `padding`, `font-size`, `width` ) */
const buttonStyleMap: Record<ButtonSize, ReturnType<typeof css>> = {
  xLarge: css`
    gap: 8px;
    height: 48px;
    padding: 0 20px;
    font-size: 16px;
    width: 100%;
  `,
  large: css`
    gap: 8px;
    height: 44px;
    padding: 0 20px;
    font-size: 14px;
  `,
  medium: css`
    gap: 4px;
    height: 36px;
    padding: 0 16px;
    font-size: 14px;
  `,
  small: css`
    gap: 4px;
    height: 32px;
    padding: 0 12px;
    font-size: 14px;
  `,
  xSmall: css`
    gap: 4px;
    height: 28px;
    padding: 0 12px;
    font-size: 13px;
  `,
};

/** `primary` 버튼 비활성화 스타일 */
const primaryDisabledStyle = css`
  color: ${palette.gray_500};
  background-color: ${palette.gray_200};
  cursor: not-allowed;
`;
/** `line` 버튼 비활성화 스타일 */
const lineDisabledStyle = css`
  color: ${palette.gray_500};
  cursor: not-allowed;
  border-color: ${palette.gray_400};
  &:hover {
    background-color: ${palette.white};
  }
`;

/** 버튼 색상에 따른 스타일 */
const colorMap: Record<Exclude<ButtonType, 'text'>, Record<ButtonColor, ReturnType<typeof css>>> = {
  primary: {
    blue_500: css`
      background-color: ${palette.blue_500};
      &:hover {
        background-color: ${palette.blue_600};
      }
      &:active {
        background-color: ${palette.blue_600};
      }
      &:disabled {
        ${primaryDisabledStyle}
        color: ${palette.white};
        background-color: ${palette.blue_300};
      }
    `,
    rc_blue_500: css`
      background-color: ${palette.rc_blue_500};
      &:hover {
        background-color: ${palette.rc_blue_600};
      }
      &:active {
        background-color: ${palette.rc_blue_600};
      }
      &:disabled {
        ${primaryDisabledStyle}
        color: ${palette.white};
        background-color: ${palette.rc_blue_100};
      }
    `,
    black: css`
      background-color: ${palette.black};
      &:hover {
        background-color: ${palette.gray_800};
      }
      &:active {
        background-color: ${palette.gray_800};
      }
      &:disabled {
        ${primaryDisabledStyle}
      }
    `,
    red_500: css`
      background-color: ${palette.red_500};
      &:hover {
        background-color: ${palette.red_600};
      }
      &:active {
        background-color: ${palette.red_600};
      }
      &:disabled {
        ${primaryDisabledStyle}
      }
    `,
    orange_500: css`
      background-color: ${palette.orange_500};
      &:hover {
        background-color: ${palette.orange_600};
      }
      &:active {
        background-color: ${palette.orange_600};
      }
      &:disabled {
        ${primaryDisabledStyle}
      }
    `,
    green_500: css`
      background-color: ${palette.green_500};
      &:hover {
        background-color: ${palette.green_600};
      }
      &:active {
        background-color: ${palette.green_600};
      }
      &:disabled {
        ${primaryDisabledStyle}
      }
    `,
  },
  line: {
    blue_500: css`
      border: 1px solid ${palette.blue_500};
      color: ${palette.blue_500};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.blue_100};
      }
      &:active {
        background-color: ${palette.blue_100};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
    rc_blue_500: css`
      border: 1px solid ${palette.rc_blue_500};
      color: ${palette.rc_blue_500};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.rc_blue_50};
      }
      &:active {
        background-color: ${palette.rc_blue_50};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
    black: css`
      border: 1px solid ${palette.gray_400};
      color: ${palette.black};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.gray_200};
      }
      &:active {
        background-color: ${palette.gray_200};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
    red_500: css`
      border: 1px solid ${palette.red_500};
      color: ${palette.red_500};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.red_100};
      }
      &:active {
        background-color: ${palette.red_100};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
    orange_500: css`
      border: 1px solid ${palette.orange_500};
      color: ${palette.orange_500};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.orange_100};
      }
      &:active {
        background-color: ${palette.orange_100};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
    green_500: css`
      border: 1px solid ${palette.green_500};
      color: ${palette.green_500};
      background-color: ${palette.white};

      &:hover {
        background-color: ${palette.green_100};
      }
      &:active {
        background-color: ${palette.green_100};
      }
      &:disabled {
        ${lineDisabledStyle}
      }
    `,
  },
};

/** 버튼 크기에 맞는 아이콘 크기 */
const iconSizeMap: Record<ButtonSize, ReturnType<typeof css>> = {
  xLarge: css`
    svg {
      width: 20px;
      height: 20px;

      *[fill]:not([fill='none']) {
        fill: ${palette.white};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.white};
      }
    }
  `,
  large: css`
    svg {
      width: 18px;
      height: 18px;

      *[fill]:not([fill='none']) {
        fill: ${palette.white};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.white};
      }
    }
  `,
  medium: css`
    svg {
      width: 18px;
      height: 18px;

      *[fill]:not([fill='none']) {
        fill: ${palette.white};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.white};
      }
    }
  `,
  small: css`
    svg {
      width: 16px;
      height: 16px;

      *[fill]:not([fill='none']) {
        fill: ${palette.white};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.white};
      }
    }
  `,
  xSmall: css`
    svg {
      width: 14px;
      height: 14px;

      *[fill]:not([fill='none']) {
        fill: ${palette.white};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.white};
      }
    }
  `,
};

type ButtonBlockProps = Required<Pick<ButtonProps, 'buttonType' | 'size' | 'color' | 'disabled'>> &
  React.ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonBlock = styled.button<ButtonBlockProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${palette.white};
  border-radius: 4px;
  transition: all 0.3s;
  outline: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  white-space: nowrap;

  &:disabled {
    cursor: not-allowed;

    svg {
      *[fill]:not([fill='none']) {
        fill: ${palette.gray_500};
      }
      *[stroke]:not([stroke='none']) {
        stroke: ${palette.gray_500};
      }
    }
  }

  // 버튼 스타일 결정 ( gap, height, padding, font-size, width )
  ${({ size }) => buttonStyleMap[size]}

  // 버튼 형태 및 색상 결정
  ${({ buttonType, color }) => {
    if (buttonType === 'text') {
      return css`
        color: ${palette.gray_800};
        &:hover {
          color: ${palette.black};
          background-color: ${palette.gray_100};
        }
        &:active {
          color: ${palette.black};
          background-color: ${palette.gray_100};
        }
        &:disabled {
          color: ${palette.gray_500};
          background-color: transparent;
        }
      `;
    }

    return colorMap[buttonType][color];
  }}

  // 버튼 내부 아이콘 크기 결정
  ${({ size }) => iconSizeMap[size]}

  // 버튼 내부 아이콘 색상 결정
  ${({ buttonType, color }) => {
    if (buttonType === 'primary') {
      // 예외적인 disabled 색상
      if (color === 'blue_500' || color === 'rc_blue_500') {
        return css`
          svg {
            *[fill]:not([fill='none']) {
              fill: ${palette.white} !important;
            }
            *[stroke]:not([stroke='none']) {
              stroke: ${palette.white} !important;
            }
          }
        `;
      }

      return css`
        svg {
          *[fill]:not([fill='none']) {
            fill: ${palette.white};
          }
          *[stroke]:not([stroke='none']) {
            stroke: ${palette.white};
          }
        }
      `;
    }
    if (buttonType === 'line') {
      return css`
        svg {
          *[fill]:not([fill='none']) {
            fill: ${palette[color]};
          }
          *[stroke]:not([stroke='none']) {
            stroke: ${palette[color]};
          }
        }
      `;
    }
    if (buttonType === 'text') {
      return css`
        svg {
          *[fill]:not([fill='none']) {
            fill: ${palette.gray_800};
          }
          *[stroke]:not([stroke='none']) {
            stroke: ${palette.gray_800};
          }
        }
      `;
    }
  }}
`;

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * 버튼 형태
   *
   * @default 'primary'
   */
  buttonType?: ButtonType;
  /**
   * 버튼 크기
   *
   * @default 'medium'
   */
  size?: ButtonSize;
  /**
   * 버튼 색상
   *
   * @default 'blue_500' or 'rc_blue_500'
   */
  color?: ButtonColor;
  /**
   * 버튼 비활성화 여부
   *
   * @default false
   */
  disabled?: boolean;
  /**
   * 좌측 아이콘
   */
  leftIcon?: React.ReactNode;
  /**
   * 우측 아이콘
   */
  rightIcon?: React.ReactNode;
}

/**
 * 공용 버튼 컴포넌트
 *
 * @link [Figma](https://www.figma.com/design/1bkq4kOmIy25uFg9VvtIet/%EB%94%94%EC%9E%90%EC%9D%B8%EC%8B%9C%EC%8A%A4%ED%85%9C_RENEW?node-id=79-2391&t=60gnQmFaZrKn7jfW-0)
 */
const Button: React.FC<ButtonProps> = ({
  children,
  buttonType = 'primary',
  size = 'medium',
  color = 'blue_500',
  disabled = false,
  leftIcon,
  rightIcon,
  type = 'button',
  ...props
}) => {
  return (
    <ButtonBlock
      size={size}
      buttonType={buttonType}
      color={color}
      disabled={disabled}
      type={type}
      {...props}
    >
      {leftIcon}
      <span>{children}</span>
      {rightIcon}
    </ButtonBlock>
  );
};

export default Button;
