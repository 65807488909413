const palette = {
  blue_100: '#f1f9ff',
  blue_200: '#BFE4FF',
  blue_300: '#6FC5FF',
  blue_400: '#54B6FF',
  blue_500: '#17a1ff',
  blue_600: '#0087E3',

  blue_gradient_1: '#33cfff',
  blue_gradient_2: '#17a1ff',

  red_100: '#fdf3f3',
  red_500: '#ff0322',
  red_600: '#CF0019',

  orange_100: '#FFF2EA',
  orange_500: '#fe883c',
  orange_600: '#F16A26',

  green_100: '#E3FCF2',
  green_500: '#1bc481',
  green_600: '#159763',

  yellow_100: '#FFF7D4',
  yellow_500: '#ecbd00',

  indigo_500: '#005F74',
  indigo_600: '#034758',

  rc_blue_50: '#F2F5FE',
  rc_blue_100: '#CED7F9',
  rc_blue_200: '#A0B1F4',
  rc_blue_300: '#728CEF',
  rc_blue_400: '#4566E9',
  rc_blue_500: '#1535B3',
  rc_blue_600: '#102785',

  rc_red_100: '#ffe8ef',
  rc_red_500: '#711610',
  rc_yellow_100: '#ffeabf',
  rc_yellow_500: '#623c00',
  rc_orange_100: '#ffe7d8',
  rc_orange_500: '#673718',
  rc_green_100: '#d7f3d4',
  rc_green_500: '#0d3e24',
  rc_skyblue_100: '#d9eBff',
  rc_skyblue_500: '#173347',

  white: '#ffffff',
  gray_50: '#FAFBFC',
  gray_70: '#f9f9f9',
  gray_100: '#F7F7F8',
  gray_200: '#F2F3F5',
  gray_300: '#E5E7EA',
  gray_400: '#D2D4D7',
  gray_500: '#B5B9BE',
  gray_600: '#94999E',
  gray_700: '#6B6F76',
  gray_800: '#41454D',
  gray_900: '#2E3138',

  black: '#1a1b1c',
  black_12: '#1A1B1C1F',

  rc_gray_700: '#282A3A',
  rc_gray_900: '#1D1E26',

  blue_fb: '#1877f2',
  green_naver: '#03cf5d',
  yellow_kakao: '#FEE500',
};

export default palette;
