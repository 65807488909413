import { makeUrlQueries } from '@miniintern/utils';

/** 메인 페이지 */
const HOME = '/';
/** 404 페이지 */
const NOT_FOUNT = '/404';

/** 미니인턴 리스트 페이지 */
const PROJECT = '/projects';
/** 미니인턴 상세 페이지 */
const PROJECT_DETAIL = '/projects/[id]';
/** 미니인턴 설문조사 페이지 */
const PROJECT_SERVEY = '/projects/[id]/survey';
/** 미니인턴 설문조사 완료 페이지 */
const PROJECT_SERVEY_COMPLETE = '/projects/[id]/survey/complete';
/** 미니인턴 참가신청 페이지 */
const PROJECT_APPLY = '/projects/[id]/apply';
/** 미니인턴 참가신청 완료페이지 */
const PROJECT_APPLY_SUCCESS = '/projects/[id]/apply/success';
/** 미니인턴 참가자 추가 페이지 */
const PROJECT_PARTICIPATE_ADD_PARTICIPANT = '/projects/[id]/participate/add-participant'; //* 참가자 추가

/** 스킬업 리스트 페이지 */
const SKILLUP = '/skillup';
/** 스킬업 상세 페이지 */
const SKILLUP_DETAIL = '/skillup/[id]';
/** 스킬업 강의 페이지 */
const SKILLUP_DETAIL_COURSE_PLAY = '/skillup/[id]/course';

/** 채용관 리스트 페이지 */
const RECRUITMENT = '/recruitment';
/** 채용관 상세 페이지 */
const RECRUITMENT_DETAIL = '/recruitment/[id]';

/** 미니봇 페이지 */
const MINIBOT = '/minibot';
/** 미니봇 상세 페이지 */
const MINIBOT_DETAIL = '/minibot/[id]';

/** M클래스 리스트 페이지 */
const EVENT = '/event';
/** M클래스 상세 페이지 */
const EVENT_DETAIL = '/event/[id]';
/**  M클래스 미리보기 페이지 */
const EVENT_PREVIEW = '/event/preview';
/** M클래스 참가신청 미리보기 페이지 */
const EVENT_PARTICIPATE_PREVIEW = '/event/participate/preview';
/** M클래스 참가신청 페이지 */
const EVENT_PARTICIPATE = '/event/participate/[id]';
/** M클래스 참가신청 완료 페이지 */
const EVENT_APPLY_SUCCESS = '/event/[id]/apply/success';

/** 해피폴리오 리스트 페이지 */
const HAPPYFOLIO = '/happyfolio';
/** 해피폴리오 상세 페이지 */
const HAPPYFOLIO_DETAIL = '/happyfolio/[id]';

/** 서비스 소개 페이지 */
const ABOUT = '/about';

/** 헬프센터 페이지 */
const HELPCENTER = '/helpcenter';
/** 공지사항 페이지 */
const HELPCENTER_NOTICES = '/helpcenter/notices';
/** 공지사항 상세 페이지 */
const HELPCENTER_NOTICES_DETAIL = '/helpcenter/notices/[id]';
/** 자주하는 질문 페이지 */
const HELPCENTER_QNA = '/helpcenter/qna';
/** 이용약관 페이지 */
const HELPCENTER_TERMS = '/helpcenter/terms';
/** 개인정보처리방침 페이지 */
const HELPCENTER_PRIVACY = '/helpcenter/privacy';
/** 환불처리규정 페이지 */
const HELPCENTER_REFUND = '/helpcenter/refund';
/** 해피폴리오 호스트 계약서 페이지 */
const HELPCENTER_HOST_AGREEMENT = '/helpcenter/hostAgreement';
/** 채용관 호스트 계약서 페이지 */
const HELPCENTER_RECRUITMENT = '/helpcenter/recruitment';

/** 내 미니인턴 페이지 */
const ME_PROJECT = '/me/project';

/** 내 M클래스 페이지 */
const ME_EVENT = '/me/event';
/** 내 M클래스 호스트 페이지 */
const ME_EVENT_HOST = '/me/event/host';
/** 내 M클래스 호스트 등록 페이지 */
const ME_EVENT_REGISTER = '/me/event/host/register';
/** 내 M클래스 상세 페이지 */
const ME_EVENT_ITEM = '/me/event/host/[id]';
/** 내 M클래스 수정 페이지 */
const ME_EVENT_EDIT = '/me/event/host/edit/[id]';

/** 내 지원서류 관리 페이지 */
const ME_DOCUMENTS = '/me/documents';
/** 내 지원 현황 관리 페이지 */
const ME_APPLICATIONS = '/me/applications';
/** 내 입사 완료 축하 페이지 */
const ME_APPLICATIONS_JOINED = '/me/applications/[urlSlug]/joined';
/** 내 취업성과금 신청 페이지 */
const ME_APPLICATIONS_APPLY_SETTLE_ACCOUNT =
  '/me/applications/apply-settle-account/[urlSlug]/[applicationId]';
/** 내 헤드헌팅 페이지 */
const ME_HEAD_HUNTING = '/me/applications/head-hunting';
/** 내 정보 변경 페이지 */
const ME_ACCOUNT = '/me/account';
/** 내 이력서 생성 페이지 */
const ME_DOCUMENTS_RESUME_CREATE = '/me/documents/resume/create';
/** 내 이력서 수정 페이지 */
const ME_DOCUMENTS_RESUME_UPDATE = '/me/documents/resume/update/[urlSlug]';

/** 내 스킬업 강의 페이지 */
const ME_SKILLUP_COURSE = '/me/skillup/course';
/** 내 스킬업 결제 내역 페이지 */
const ME_SKILLUP_PAYHISTORY = '/me/skillup/pay-history';

/** 내 해피폴리오 호스트 페이지 */
const ME_HAPPYFOLIO_HOST = '/me/happyfolio/host';
/** 내 해피폴리오 페이지 */
const ME_HAPPYFOLIO = '/me/happyfolio';
/** 내 해피폴리오 미리보기 페이지 */
const ME_HAPPYFOLIO_PREVIEW = '/me/happyfolio/preview';
/** 내 해피폴리오 호스트 가입 페이지 */
const ME_HAPPYFOLIO_REGISTER = '/me/happyfolio/host/register';
/** 내 해피폴리오 호스트 수정 페이지 */
const ME_HAPPYFOLIO_EDIT = '/me/happyfolio/host/edit/[id]';
/** 내 해피폴리오 호스트 공지사항 페이지 */
const ME_HAPPYFOLIO_NOTICES = '/me/happyfolio/host/notice';
/** 내 해피폴리오 호스트 공지사항 상세 페이지 */
const ME_HAPPYFOLIO_NOTICE_DETAIL = '/me/happyfolio/host/notice/[id]';
/** 내 해피폴리오 호스트 자주하는 질문 페이지 */
const ME_HAPPYFOLIO_QNA = '/me/happyfolio/host/qna';

/** 기업회원 페이지 */
const WITH_US = '/withus';
/** 회원가입 페이지 */
const SIGN_UP = '/auth/signup';
/** 로그인 페이지 */
const LOGIN = '/auth/login';
/** 비밀번호 찾기 페이지 */
const AUTH_PASSWORD = '/auth/password';
/** 이메일 인증 페이지 */
const AUTH_EMAIL_AUTHENTICATION = '/auth/email-authentication';

/** 매거진 상세 페이지 */
const MAGAZINE_DETAIL = '/magazine/[id]';

const routes = {
  /** 메인 페이지 */
  home: HOME,
  /** 404 페이지 */
  notFount: NOT_FOUNT,

  /** 미니인턴 리스트 페이지 */
  project: PROJECT,
  /** 미니인턴 상세 페이지 */
  projectDetail: (projectId: number | string) => {
    return PROJECT_DETAIL.replace('[id]', String(projectId));
  },
  /** 미니인턴 설문조사 페이지 */
  projectSurvey: (projectId: number | string) => {
    return PROJECT_SERVEY.replace('[id]', String(projectId));
  },
  /** 미니인턴 설문조사 완료 페이지 */
  projectSurveyComplete: (projectId: number | string) => {
    return PROJECT_SERVEY_COMPLETE.replace('[id]', String(projectId));
  },
  /** 미니인턴 참가신청 페이지 */
  projectApply: (projectId: number | string) => {
    return PROJECT_APPLY.replace('[id]', String(projectId));
  },
  /** 미니인턴 참가신청 완료페이지 */
  projectApplySuccess: (projectId: number | string) => {
    return PROJECT_APPLY_SUCCESS.replace('[id]', String(projectId));
  },
  /** 미니인턴 참가자 추가 페이지 */
  projectParticipateAddParticipant: (projectId: number | string) => {
    return PROJECT_PARTICIPATE_ADD_PARTICIPANT.replace('[id]', String(projectId));
  },

  /** 스킬업 리스트 페이지 */
  skillup: SKILLUP,
  /** 스킬업 상세 페이지 */
  skillupDetail: (courseId: number | string) => {
    return SKILLUP_DETAIL.replace('[id]', String(courseId));
  },
  /** 스킬업 강의 페이지 */
  skillupDetailCoursePlay: (courseId: number | string) => {
    return SKILLUP_DETAIL_COURSE_PLAY.replace('[id]', String(courseId));
  },

  /** 채용관 리스트 페이지 */
  recruitment: RECRUITMENT,
  /** 채용관 상세 페이지 */
  recruitmentDetail: (recruitmentId: number | string) => {
    return RECRUITMENT_DETAIL.replace('[id]', String(recruitmentId));
  },

  /** 미니봇 페이지 */
  minibot: MINIBOT,
  /** 미니봇 상세 페이지 */
  minibotDetail: (companyId: number) => {
    return MINIBOT_DETAIL.replace('[id]', String(companyId));
  },

  /** M클래스 리스트 페이지 */
  event: EVENT,
  /** M클래스 상세 페이지 */
  eventDetail: (eventId: number | string) => {
    return EVENT_DETAIL.replace('[id]', String(eventId));
  },
  /** M클래스 미리보기 페이지 */
  eventPreview: EVENT_PREVIEW,
  /** M클래스 참가신청 미리보기 페이지 */
  eventParticipatePreview: EVENT_PARTICIPATE_PREVIEW,
  /** M클래스 참가신청 페이지 */
  eventParticipate: (eventId: number | string) => {
    return EVENT_PARTICIPATE.replace('[id]', String(eventId));
  },
  /** M클래스 참가신청 완료 페이지 */
  eventApplySuccess: (eventId: number | string) => {
    return EVENT_APPLY_SUCCESS.replace('[id]', String(eventId));
  },

  /** 해피폴리오 리스트 페이지 */
  happyfolio: HAPPYFOLIO,
  /** 해피폴리오 상세 페이지 */
  happyfolioDetail: (happyfolioId: number | string) => {
    return HAPPYFOLIO_DETAIL.replace('[id]', String(happyfolioId));
  },

  /** 서비스 소개 페이지 */
  about: ABOUT,

  /** 헬프센터 페이지 */
  helpcenter: HELPCENTER,
  /** 공지사항 페이지 */
  helpcenterNotices: HELPCENTER_NOTICES,
  /** 공지사항 상세 페이지 */
  helpcenterNoticeDetail: (noticeId: number | string) => {
    return HELPCENTER_NOTICES_DETAIL.replace('[id]', String(noticeId));
  },
  /** 자주하는 질문 페이지 */
  helpcenterQna: HELPCENTER_QNA,
  /** 이용약관 페이지 */
  helpcenterTerms: HELPCENTER_TERMS,
  /** 개인정보처리방침 페이지 */
  helpcenterPrivacy: HELPCENTER_PRIVACY,
  /** 환불처리규정 페이지 */
  helpcenterRefund: HELPCENTER_REFUND,
  /** 해피폴리오 호스트 계약서 페이지 */
  helpcenterHostAgreement: HELPCENTER_HOST_AGREEMENT,
  /** 채용관 호스트 계약서 페이지 */
  helpcenterRecruitment: HELPCENTER_RECRUITMENT,

  /** 내 미니인턴 페이지 */
  meProject: ME_PROJECT,

  /** 내 M클래스 페이지 */
  meEvent: ME_EVENT,
  /** 내 M클래스 호스트 페이지 */
  meEventHost: ME_EVENT_HOST,
  /** 내 M클래스 호스트 등록 페이지 */
  meEventRegister: ME_EVENT_REGISTER,
  /** 내 M클래스 상세 페이지 */
  meEventItem: (eventId: number | string) => {
    return ME_EVENT_ITEM.replace('[id]', String(eventId));
  },
  /** 내 M클래스 수정 페이지 */
  meEventEdit: (eventId: number | string) => {
    return ME_EVENT_EDIT.replace('[id]', String(eventId));
  },

  /** 내 지원서류 관리 페이지 */
  meDocuments: ME_DOCUMENTS,
  /** 내 지원 현황 관리 페이지 */
  meApplications: ME_APPLICATIONS,
  /** 내 입사 완료 축하 페이지 */
  meApplicationsJoined: (urlSlug: string) => {
    return ME_APPLICATIONS_JOINED.replace('[urlSlug]', urlSlug);
  },
  /** 내 취업성과금 신청 페이지 */
  meApplicationsApplySettleAccount: (urlSlug: string, applicationId: string | number) => {
    return ME_APPLICATIONS_APPLY_SETTLE_ACCOUNT.replace('[urlSlug]', urlSlug).replace(
      '[applicationId]',
      String(applicationId),
    );
  },
  /** 내 헤드헌팅 페이지 */
  meHeadhunting: ME_HEAD_HUNTING,
  /** 내 정보 변경 페이지 */
  meAccount: ME_ACCOUNT,
  /** 내 이력서 생성 페이지 */
  meDocumentsResumeCreate: ME_DOCUMENTS_RESUME_CREATE,
  /** 내 이력서 수정 페이지 */
  meDocumentsResumeUpdate: (urlSlug: string) => {
    return ME_DOCUMENTS_RESUME_UPDATE.replace('[urlSlug]', urlSlug);
  },

  /** 내 스킬업 강의 페이지 */
  meSkillupCourse: ME_SKILLUP_COURSE,
  /** 내 스킬업 결제 내역 페이지 */
  meSkillupPayHistory: ME_SKILLUP_PAYHISTORY,

  /** 내 해피폴리오 호스트 페이지 */
  meHappyfolioHost: (category?: 'board' | 'comments' | 'account-list' | 'support') => {
    if (category) {
      return makeUrlQueries(ME_HAPPYFOLIO_HOST, { category });
    }
    return ME_HAPPYFOLIO_HOST;
  },
  /** 내 해피폴리오 페이지 */
  meHappyfolio: (category?: 'liked' | 'purchased' | 'comments') => {
    if (category) {
      return makeUrlQueries(ME_HAPPYFOLIO, { category });
    }
    return ME_HAPPYFOLIO;
  },
  /** 내 해피폴리오 미리보기 페이지 */
  meHappyfolioPrevew: ME_HAPPYFOLIO_PREVIEW,
  /** 내 해피폴리오 호스트 가입 페이지 */
  meHappyfolioRegister: ME_HAPPYFOLIO_REGISTER,
  /** 내 해피폴리오 호스트 수정 페이지 */
  meHappyfolioEdit: (happyfolioId: number | string) => {
    return ME_HAPPYFOLIO_EDIT.replace('[id]', String(happyfolioId));
  },
  /** 내 해피폴리오 호스트 공지사항 페이지 */
  meHappyfolioNotices: ME_HAPPYFOLIO_NOTICES,
  /** 내 해피폴리오 호스트 공지사항 상세 페이지 */
  meHappyfolioNoticeDetail: (noticeId: number | string) => {
    return ME_HAPPYFOLIO_NOTICE_DETAIL.replace('[id]', String(noticeId));
  },
  /** 내 해피폴리오 호스트 자주하는 질문 페이지 */
  meHappyfolioQna: ME_HAPPYFOLIO_QNA,

  /** 기업회원 페이지 */
  withus: WITH_US,
  /** 회원가입 페이지 */
  signup: SIGN_UP,
  /** 로그인 페이지 */
  login: LOGIN,
  /** 비밀번호 찾기 페이지 */
  password: AUTH_PASSWORD,
  /** 이메일 인증 페이지 */
  emailAuthentication: AUTH_EMAIL_AUTHENTICATION,

  /** 매거진 상세 페이지 */
  magazineDetail: (magazineId: string | number) => {
    return MAGAZINE_DETAIL.replace('[id]', String(magazineId));
  },
} as const;

export default routes;
